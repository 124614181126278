import React from 'react';

import {Footer, Blog, Features, Possibility, WhatProfata, Header} from './containers';
import {Brand, CTA, Navbar} from './components';

import './App.css';

const App = () => {
  return (
    <div className='App'>
      <div className='gradient__bg'>
      <Navbar />
      <Header />
      </div>
      <Brand />
      <WhatProfata />
      <Features />
      <Possibility />
      <CTA />
      <Blog />
      <Footer />
    </div>
  )
}

export default App
