import React from 'react';
import people from '../../assets/people.png';
import face from '../../assets/databirds.png';
import './header.css';


const Header = () => {
  return (
    <div className='profata__header section__padding' id='home'>
      <div className='profata__header-content'>
        <h1 className='gradient__text'>Take control of your data and get rewards with Profata</h1>
        <p>Privacy empowered. Profit unlocked. Our platform transforms your data into dividends and rewards, giving you control and value. Your data, your earnings. Put your data to work for you and reap the benefits.</p>

        <div className="profata__header-content__input">
          <input type="email" placeholder="Your Email Address" />
          <button type="button">Get Started</button>
        </div>

        <div className="profata__header-content__people">
          <img src={people} alt="people" />
          <p >1,600 people already joined</p>
        </div>
      </div>

      <div className='profata__header-image'>
        <img src={face} alt="face from data streams" />
      </div>
    </div>
  )
}

export default Header
